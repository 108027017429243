// cspell:disable
/* eslint-disable max-len */
module.exports = {
  HomePage: {
    url: '/',
    component: 'src/pagesComponents/home/HomePageV5.tsx',
  },

  HomePageTilly: {
    url: '/tilly/',
    component: 'src/pagesComponents/home/HomePageTilly.js',
    seo: {
      title: 'Tilli, Votre réseau d\'artisans français',
      description: 'Nos artisans couturiers, maroquiniers et cordonniers vous accompagnent pour entretenir et sublimer votre garde-robe.',
      english: {
        title: 'Tilli, Your French Artisans Network',
        description: 'Our couturiers, leather craftsmen and cobblers are here to help you maintain and enhance your wardrobe.',
      },
      portuguese: {
        title: 'Tilli, Sua rede de artesãos franceses',
        description: 'Nos artesãos, marroquineros e cordonheiros estão aqui para ajudá-lo a manter e melhorar seu guarda-roupa.',
      },
      german: {
        title: 'Tilli, Ihr Netz französischer Handwerker',
        description: 'Unsere Schneider, Lederarbeiter und Schuhmacher sind hier, um Ihnen zu helfen, Ihr Kleidungsgeschäft zu pflegen und zu verbessern.',
      },
      italian: {
        title: 'Tilli, La tua rete di artigiani francesi',
        description: 'I nostri artigiani, marroquinieri e cordonieri sono qui per aiutarti a mantenere e migliorare il tuo guardaroba.',
      },
      spanish: {
        title: 'Tilli, Tu red de artesanos franceses',
        description: 'Nuestros artesanos, marroquineros y cordonieros están aquí para ayudarte a mantener y mejorar tu guardarropa.',
      },
    },
  },

  HowItWorksSEOPage: {
    url: '/comment-fonctionne-tilli-le-service-de-retoucherie-a-paris-et-marseille/',
    component: 'src/pagesComponents/home/HowItWorksPage.tsx',
    seo: {
      title: 'Le service de couturier à domicile : découvrez Tilli',
      description: 'Un service simple, de qualité et transparent : 1. La prise de RDV - 2. Le RDV avec votre Tilliste - 3. Le retour de vos vêtements',
      english: {
        title: 'Discover Tilli, the home tailoring service',
        description: 'A simple, high-quality and transparent service: 1. Make an appointment - 2. Meet your Tilliste - 3. Get your clothes back',
      },
      portuguese: {
        title: 'Descubra Tilli, o serviço de retouche e reparo',
        description: 'Um serviço simples, de alta qualidade e transparente: 1. Faça um agendamento - 2. Converse com seu Tilliste - 3. Recupere seus itens',
      },
      german: {
        title: 'Entdecken Sie Tilli, den Service für Anpassungen und Reparaturen',
        description: 'Ein einfaches, hochwertiges und transparentes Service: 1. Termin vereinbaren - 2. Treffen Sie Ihren Tillisten - 3. Holen Sie Ihre Artikel zurück',
      },
      italian: {
        title: 'Scopri Tilli, il servizio di adattamento e riparazione',
        description: 'Un servizio semplice, di alta qualità e trasparente: 1. Pianificare un appuntamento - 2. Conversare con il tuo Tilliste - 3. Recuperare i tuoi articoli',
      },
      spanish: {
        title: 'Descubre Tilli, el servicio de ajuste y reparación',
        description: 'Un servicio simple, de alta calidad y transparente: 1. Hacer una cita - 2. Conversar con tu Tilliste - 3. Recuperar tus artículos',
      },
    },
  },

  HowItWorksPage: {
    url: '/comment-fonctionne-tilli-le-service-de-retouche-et-reparation/',
    component: 'src/pagesComponents/home/HowItWorksPage.tsx',
    seo: {
      title: 'Le service de retouche et réparation : découvrez Tilli',
      description: 'Un service simple, de qualité et transparent : 1. La prise de RDV - 2. Le RDV avec votre Tilliste - 3. Le retour de vos pièces',
      english: {
        title: 'Discover Tilli, the alteration and repair service',
        description: 'A simple, high-quality and transparent service: 1. Make an appointment - 2. Meet your Tilliste - 3. Get your items back',
      },
      portuguese: {
        title: 'Descubra Tilli, o serviço de retouche e reparo',
        description: 'Um serviço simples, de alta qualidade e transparente: 1. Faça um agendamento - 2. Converse com seu Tilliste - 3. Recupere seus itens',
      },
      german: {
        title: 'Entdecken Sie Tilli, den Service für Anpassungen und Reparaturen',
        description: 'Ein einfaches, hochwertiges und transparentes Service: 1. Termin vereinbaren - 2. Treffen Sie Ihren Tillisten - 3. Holen Sie Ihre Artikel zurück',
      },
      italian: {
        title: 'Scopri Tilli, il servizio di adattamento e riparazione',
        description: 'Un servizio semplice, di alta qualità e trasparente: 1. Pianificare un appuntamento - 2. Conversare con il tuo Tilliste - 3. Recuperare i tuoi articoli',
      },
      spanish: {
        title: 'Descubre Tilli, el servicio de ajuste y reparación',
        description: 'Un servicio simple, de alta calidad y transparente: 1. Hacer una cita - 2. Conversar con tu Tilliste - 3. Recuperar tus artículos',
      },
    },
  },

  AboutPage: {
    url: '/a-propos-de-tilli/',
    component: 'src/pagesComponents/home/AboutPage.js',
    seo: {
      title: 'A propos : découvrez l’histoire derrière Tilli',
      description: 'Chez Tilli, nous partageons tous un objectif commun : celui de remettre au goût du jour le métier de couturier en le digitalisant. Le couturier échange directement avec le client pour ne pas perdre en qualité et laisser place à une expérience unique !',
      english: {
        title: 'About: discover the story behind Tilli',
        description: 'At Tilli, we all share a common goal: to bring the profession of couturier up to date by digitising it. The couturier exchanges directly with the customer to maintain quality and provide a unique experience!',
      },
      portuguese: {
        title: 'Sobre: descubra a história por trás de Tilli',
        description: 'Na Tilli, todos compartilham um objetivo comum: trazer o ofício de couturier para o século XXI digitalizando-o. O couturier troca diretamente com o cliente para manter a qualidade e proporcionar uma experiência única!',
      },
      german: {
        title: 'Über: Entdecken Sie die Geschichte von Tilli',
        description: 'Bei Tilli teilen wir uns ein gemeinsames Ziel: den Beruf des Couturiers in die 21. Jahrhundert zu digitalisieren. Der Couturier tauscht direkt mit dem Kunden, um Qualität und eine einzigartige Erfahrung zu gewährleisten!',
      },
      italian: {
        title: 'Chi siamo: scopri la storia dietro Tilli',
        description: 'A Tilli, tutti condividono un obiettivo comune: portare il mestiere del couturiere al 21° secolo digitalizzandolo. Il couturiere scambia direttamente con il cliente per mantenere la qualità e offrire un’esperienza unica!',
      },
      spanish: {
        title: 'Sobre: descubre la historia detrás de Tilli',
        description: 'En Tilli, todos compartimos un objetivo común: llevar el oficio del couturier al siglo XXI digitalizándolo. El couturier se comunica directamente con el cliente para mantener la calidad y proporcionar una experiencia única!',
      },
    },
  },

  BeTillistePage: {
    url: '/devenir-couturier/',
    component: 'src/pagesComponents/home/BeTillistePage.js',
    seo: {
      title: 'Devenir Couturier Freelance - Tilli',
      description: 'Tout savoir sur le métier de couturier Tilli. Travaillez quand vous voulez, en toute simplicité. Nous recrutons à Paris, Lille, Strasbourg, Nice, Cannes, Nantes, etc',
      english: {
        title: 'Become a Freelance Couturier - Tilli',
        description: 'Everything you need to know about the profession of Tilli couturier. Work when you want, with complete simplicity. We are recruiting in Paris, Lille, Strasbourg, Nice, Cannes, Nantes, etc.',
      },
      portuguese: {
        title: 'Seja um Couturier Freelance - Tilli',
        description: 'Tudo o que você precisa saber sobre o ofício de couturier Tilli. Trabalhe quando quiser, com total simplicidade. Estamos recrutando em Paris, Lille, Strasbourg, Nice, Cannes, Nantes, etc.',
      },
      german: {
        title: 'Werden Sie ein Freelance Couturier - Tilli',
        description: 'Alles, was Sie über die Berufsgruppe Couturier von Tilli wissen müssen. Arbeiten Sie, wann Sie möchten, mit vollständiger Einfachheit. Wir suchen in Paris, Lille, Straßburg, Nizza, Cannes, Nantes usw.',
      },
      italian: {
        title: 'Diventa un Couturier Freelance - Tilli',
        description: 'Tutto ciò che devi sapere sul mestiere di couturier di Tilli. Lavorate quando volete, con totalità di semplicità. Cerchiamo in Parigi, Lille, Strasbourg, Nice, Cannes, Nantes, ecc.',
      },
      spanish: {
        title: 'Ser un Couturier Freelance - Tilli',
        description: 'Todo lo que necesitas saber sobre el oficio de couturier de Tilli. Trabajen cuando quieran, con total simplicidad. Buscamos en París, Lille, Estrasburgo, Niza, Cannes, Nantes, etc.',
      },
    },
  },

  CustomerFeedbacksPage: {
    url: '/avis-de-nos-clients/',
    component: 'src/pagesComponents/home/CustomerFeedbacksPage.js',
  },

  LegalTerms: {
    url: '/mentions-legales/',
    component: 'src/pagesComponents/home/LegalTerms.js',
    seo: {
      title: 'Mentions Légales & Conditions Générales - Tilli',
      english: {
        title: 'Legal Terms & General Conditions - Tilli',
      },
      portuguese: {
        title: 'Termos Legais & Condições Gerais - Tilli',
      },
      german: {
        title: 'Rechtliches & Allgemeine Bedingungen - Tilli',
      },
      italian: {
        title: 'Termini Legali & Condizioni Generali - Tilli',
      },
      spanish: {
        title: 'Términos Legales & Condiciones Generales - Tilli',
      },
    },
  },

  CookiesPrivacy: {
    url: '/politique-cookies/',
    component: 'src/pagesComponents/home/CookiesPrivacy.js',
    seo: {
      title: 'Politiques Cookies - Tilli',
      english: {
        title: 'Cookies Policy - Tilli',
      },
      portuguese: {
        title: 'Política de Cookies - Tilli',
      },
      german: {
        title: 'Cookies-Richtlinie - Tilli',
      },
      italian: {
        title: 'Politica dei Cookies - Tilli',
      },
      spanish: {
        title: 'Política de Cookies - Tilli',
      },
    },
  },

  ShopifyPrivacy: {
    url: '/tilli-app-privacy-policy/',
    component: 'src/pagesComponents/home/ShopifyPrivacy.js',
    seo: {
      title: 'Tilli Privacy Policy',
      english: {
        title: 'Tilli Privacy Policy',
      },
      portuguese: {
        title: 'Política de Privacidade da Tilli',
      },
      german: {
        title: 'Tilli Datenschutzrichtlinie',
      },
      italian: {
        title: 'Politica di privacy di Tilli',
      },
      spanish: {
        title: 'Política de privacidad de Tilli',
      },
    },
  },

  Login: {
    url: '/se-connecter/',
    component: 'src/pagesComponents/myAccount/Login.js',
    seo: {
      title: 'Se connecter - Tilli',
      description: 'Se connecter à son compte Tilli.',
      english: {
        title: 'Login - Tilli',
        description: 'Login to your Tilli account.',
      },
      portuguese: {
        title: 'Login - Tilli',
        description: 'Login to your Tilli account.',
      },
      german: {
        title: 'Login - Tilli',
        description: 'Login to your Tilli account.',
      },
      italian: {
        title: 'Login - Tilli',
        description: 'Login to your Tilli account.',
      },
      spanish: {
        title: 'Login - Tilli',
        description: 'Login to your Tilli account.',
      },
    },
  },

  ForgottenPasswordRequest: {
    url: '/mot-de-passe-oublie/',
    component: 'src/pagesComponents/myAccount/ForgottenPasswordRequest.js',
    seo: {
      title: 'Mot de passe oublié - Tilli',
      english: {
        title: 'Forgotten Password - Tilli',
      },
      portuguese: {
        title: 'Senha esquecida - Tilli',
      },
      german: {
        title: 'Passwort vergessen - Tilli',
      },
      italian: {
        title: 'Password dimenticata - Tilli',
      },
      spanish: {
        title: 'Olvidé mi contraseña - Tilli',
      },
    },
  },

  ResetPassword: {
    // let the url without trailing slash, otherwise the page didn't load
    url: '/nouveau-mot-de-passe',
    component: 'src/pagesComponents/myAccount/ResetPasswordContainer.js',
    seo: { title: 'Nouveau mot de passe - Tilli' },
  },

  Insurance: {
    url: '/assurance-MMA-en-toute-serenite/',
    component: 'src/pagesComponents/home/Insurance.js',
    seo: {
      title: 'Tous vos vêtements assurés par MMA - Tilli',
      description: 'Toutes les commandes passées chez Tilli sont assurées par notre partenaire MMA. Tilli prend soin de ses clients et couturiers !',
      english: {
        title: 'All your clothes insured by MMA - Tilli',
        description: 'All orders placed with Tilli are insured by our partner MMA. Tilli takes care of its clients and couturiers!',
      },
      portuguese: {
        title: 'Todos seus vêtements cobertos pela MMA - Tilli',
        description: 'Todas as encomendas feitas com Tilli são cobertas pelo nosso parceiro MMA. Tilli cuida de seus clientes e couturiers!',
      },
      german: {
        title: 'Alle Ihre Kleidung von MMA abgedeckt - Tilli',
        description: 'Alle Aufträge, die bei Tilli platziert werden, sind von unserem Partner MMA abgedeckt. Tilli kümmert sich um seine Kunden und Couturiers!',
      },
      italian: {
        title: 'Tutti i vostri vestiti coperti da MMA - Tilli',
        description: 'Tutte le ordinazioni effettuate con Tilli sono coperte dal nostro partner MMA. Tilli si prende cura dei suoi clienti e couturiers!',
      },
      spanish: {
        title: 'Todos tus vestidos cubiertos por MMA - Tilli',
        description: 'Todas las órdenes colocadas con Tilli están cubiertas por nuestro partner MMA. Tilli se preocupa por sus clientes y couturiers!',
      },
    },
  },

  InsuranceTilliste: {
    url: '/assurance-MMA-tilliste/',
    component: 'src/pagesComponents/home/InsuranceTilliste.js',
    seo: {
      title: 'Tous nos tillistes sont couverts par MMA - Tilli',
      description: 'En tant que tilliste, travaillez sans crainte grâce à notre partenaire MMA. Toutes vos missions sont couvertes par MMA.',
      english: {
        title: 'All our Tillistes are covered by MMA - Tilli',
        description: 'As a Tilliste, work without fear thanks to our partner MMA. All your missions are covered by MMA.',
      },
      portuguese: {
        title: 'Todos nossos tillistes são cobertos pela MMA - Tilli',
        description: 'Como tilliste, trabalhe sem medo graças ao nosso parceiro MMA. Todas suas missões são cobertas pela MMA.',
      },
      german: {
        title: 'Alle unserer Tillisten sind von MMA abgedeckt - Tilli',
        description: 'Als Tilliste arbeiten Sie ohne Angst, da unser Partner MMA Ihnen hilft. Alle Ihre Aufträge sind von MMA abgedeckt.',
      },
      italian: {
        title: 'Tutti i nostri tillisti sono coperti da MMA - Tilli',
        description: 'Come tillista, lavora senza paura grazie al nostro partner MMA. Tutte le tue missioni sono coperte da MMA.',
      },
      spanish: {
        title: 'Todos nuestros tillistes están cubiertos por MMA - Tilli',
        description: 'Como tilliste, trabaja sin miedo gracias a nuestro partner MMA. Todas tus misiones están cubiertas por MMA.',
      },
    },
  },

  EventsPage: {
    url: '/nos-evenements/',
    component: 'src/templates/EventsTemplate.js',
    seo: {
      title: 'Nos événements - Tilli',
      photo: '/eventPage.jpg',
      description: 'Atelier broderie, transformation de robe de mariée, retouches et réparations en faveur du Green Friday, création d’accessoires sur-mesure... Pour préparer les fêtes, nos Tillistes sont sur tous les fronts et multiplient les activités en boutiques chez nos marques partenaires.',
      english: {
        title: 'Our events - Tilli',
        description: 'Workshop embroidery, transformation of a wedding dress, alterations and repairs in favor of Green Friday, creation of custom accessories... To prepare the festivities, our Tillistes are on all fronts and multiply activities in our partner shops.',
      },
      portuguese: {
        title: 'Nossos eventos - Tilli',
        description: 'Atelier de bordado, transformação de vestido de noiva, retouches e reparos em favor do Green Friday, criação de acessórios sob-medida... Para preparar as festas, nossos Tillistes estão em todos os fronts e multiplicam atividades nas nossas lojas parceiras.',
      },
      german: {
        title: 'Unsere Events - Tilli',
        description: 'Workshop Embroidery, Transformation eines Brautkleides, Reparaturen und Anpassungen im Auftrag von Green Friday, Erstellung von benutzerdefinierten Accessoires... Um die Feiertage zu bereiten, sind unsere Tillisten auf allen Fronten und multiplizieren Aktivitäten in unseren Partnern-Shops.',
      },
      italian: {
        title: 'I nostri eventi - Tilli',
        description: 'Atelier di bordatura, trasformazione di un vestito di nozze, riparazioni e adattamenti in favore di Green Friday, creazione di accessori personalizzati... Per preparare le feste, i nostri Tillisti sono su tutti i fronti e moltiplicano attività nelle nostre shop partner.',
      },
      spanish: {
        title: 'Nuestros eventos - Tilli',
        description: 'Taller de bordado, transformación de vestido de novia, reparaciones y ajustes en favor de Green Friday, creación de accesorios personalizados... Para preparar las fiestas, nuestros Tillistes están en todos los fronts y multiplican actividades en nuestras tiendas partner.',
      },
    },
  },

  PartnersPage: {
    url: '/ils-nous-font-confiance-les-partenaires-tilli/',
    component: 'src/pagesComponents/home/PartnersPage.js',
    seo: {
      title: 'Nos partenaires - Tilli',
      description: 'Ils font confiance à Tilli pour leurs retouches : Madura, Sézane, Isabel Marant, Violette Sauvage et bien d’autres',
      english: {
        title: 'Our partners - Tilli',
        description: 'They trust Tilli for their alterations: Madura, Sézane, Isabel Marant, Violette Sauvage and many others',
      },
      portuguese: {
        title: 'Nossos parceiros - Tilli',
        description: 'Eles confiam em Tilli para suas retouches: Madura, Sézane, Isabel Marant, Violette Sauvage e muitos outros',
      },
      german: {
        title: 'Unsere Partner - Tilli',
        description: 'Sie vertrauen auf Tilli für ihre Reparaturen: Madura, Sézane, Isabel Marant, Violette Sauvage und viele andere',
      },
      italian: {
        title: 'I nostri partner - Tilli',
        description: 'Lasciano a Tilli il loro lavoro: Madura, Sézane, Isabel Marant, Violette Sauvage e molti altri',
      },
      spanish: {
        title: 'Nuestros partners - Tilli',
        description: 'Confían en Tilli para sus reparaciones: Madura, Sézane, Isabel Marant, Violette Sauvage y muchos más',
      },
    },
  },

  WhyBecomePartner: {
    url: '/tilli-votre-partenaire-retouche-reparation-ecommerce-boutique/',
    component: 'src/pagesComponents/home/WhyBecomePartner.tsx',
    seo: {
      title: 'Nous choisir comme partenaire retouches - Tilli',
      description: 'Vous êtes une marque ou une boutique, pourquoi choisir Tilli comme partenaire retouches ? Pour améliorer l’expérience client, pour simplifier votre gestion, et plus…',
      english: {
        title: 'Why choose us as a partner for alterations?',
        description: 'You are a brand or a shop, why choose Tilli as a partner for alterations? To improve the customer experience, to simplify your management, and more...',
      },
      portuguese: {
        title: 'Por que escolher o Tilli como parceiro de retouches?',
        description: 'Você é uma marca ou uma loja, por que escolher o Tilli como parceiro de retouches? Para melhorar a experiência do cliente, para simplificar sua gestão e mais...',
      },
      german: {
        title: 'Warum wählen Sie uns als Partner für Reparaturen?',
        description: 'Sie sind eine Marke oder ein Geschäft, warum wählen Sie Tilli als Partner für Reparaturen? Um die Kundenerfahrung zu verbessern, um Ihre Verwaltung zu vereinfachen und mehr...',
      },
      italian: {
        title: 'Perché scegliere Tilli come partner per le riparazioni?',
        description: 'Sei una marca o un negozio, perché scegliere Tilli come partner per le riparazioni? Per migliorare l’esperienza del cliente, per semplificare la tua gestione e molto altro...',
      },
      spanish: {
        title: '¿Por qué elegirnos como partner de retouches?',
        description: '¿Eres una marca o una tienda, por qué elegirnos como partner de retouches? Para mejorar la experiencia del cliente, para simplificar tu gestión y más...',
      },
    },
  },

  FAQPage: {
    url: '/les-questions-les-plus-frequentes-tilli-retouche/',
    component: 'src/pagesComponents/home/FAQPage.js',
    seo: {
      title: 'FAQ : les questions les plus fréquentes - Tilli',
      description: 'Retouches, délai de livraison, villes couvertes ? Toutes les réponses à vos question sur le service Tilli',
      english: {
        title: 'FAQ: Frequently Asked Questions - Tilli',
        description: 'Alterations, delivery time, covered cities? All answers to your questions about the Tilli service',
      },
      portuguese: {
        title: 'FAQ: Perguntas frequentes - Tilli',
        description: 'Retouches, prazo de entrega, cidades cobertas? Todas as respostas às suas perguntas sobre o serviço Tilli',
      },
      german: {
        title: 'FAQ: Häufig gestellte Fragen - Tilli',
        description: 'Reparaturen, Lieferzeit, abgedeckte Städte? Alle Antworten auf Ihre Fragen zum Tilli-Service',
      },
      italian: {
        title: 'FAQ: Domande frequenti - Tilli',
        description: 'Riparazioni, tempo di consegna, città coperte? Tutte le risposte alle tue domande sul servizio Tilli',
      },
    },
  },

  Invite: {
    url: '/i/',
    component: 'src/pagesComponents/home/Invite.js',
    seo: {
      title: '5€ offerts sur votre première commande Tilli',
      photo: '/photo-header-referral-code.jpg',
      description: 'Tilli, Votre réseau d‘artisans français',
      english: {
        title: '5€ off on your first Tilli order',
        photo: '/photo-header-referral-code.jpg',
        description: 'Tilli, Your French Artisans Network',
      },
      portuguese: {
        title: '5€ off on your first Tilli order',
        photo: '/photo-header-referral-code.jpg',
        description: 'Tilli, Your French Artisans Network',
      },
      german: {
        title: '5€ off on your first Tilli order',
        photo: '/photo-header-referral-code.jpg',
        description: 'Tilli, Your French Artisans Network',
      },
      italian: {
        title: '5€ off on your first Tilli order',
        photo: '/photo-header-referral-code.jpg',
        description: 'Tilli, Your French Artisans Network',
      },
      spanish: {
        title: '5€ off on your first Tilli order',
        photo: '/photo-header-referral-code.jpg',
        description: 'Tilli, Your French Artisans Network',
      },
    },
    isRouteNotIndexedBySearchEngine: true,
  },

  HomePageClacDesDoigts: {
    url: '/clac-des-doigts/',
    component: 'src/pagesComponents/home/HomePageClacDesDoigts.js',
  },

  PricingsPage: {
    url: '/nos-tarifs/',
    component: 'src/pagesComponents/home/PricingsPage.js',
    seo: {
      title: 'Prix Retouche : Tarifs des Retouche de Vêtements - Tilli',
      description: 'Tarifs & Prix des Retouches Vêtements : Découvrez tous nos prix pour la retouche de vos vêtements & Rideaux ✅Couturier 7/7 de 7h à 22h',
      english: {
        title: 'Alterations Prices: Clothing Alterations Prices - Tilli',
        description: 'Clothing Alterations Prices: Discover all our prices for the alteration of your clothes & Curtains ✅Seamstress 7/7 from 7am to 10pm',
      },
      portuguese: {
        title: 'Preços de retouches: Preços de retouches de roupas - Tilli',
        description: 'Preços de retouches de roupas: Descubra todos os nossos preços para a retouche de suas roupas & Cortinas ✅Costureira 7/7 das 7h às 22h',
      },
      german: {
        title: 'Preise für Reparaturen: Preise für Reparaturen von Kleidern - Tilli',
        description: 'Preise für Reparaturen von Kleidern: Entdecken Sie alle unsere Preise für die Reparatur Ihrer Kleider & Vorhänge ✅Schneider 7/7 von 7 bis 22 Uhr',
      },
      italian: {
        title: 'Prezzi per le riparazioni: Prezzi per le riparazioni di vestiti - Tilli',
        description: 'Prezzi per le riparazioni di vestiti: Scopri tutti i nostri prezzi per le riparazioni dei tuoi vestiti & tende ✅Costuriere 7/7 dalle 7 alle 22',
      },
      spanish: {
        title: 'Precios de reparaciones: Precios de reparaciones de ropa - Tilli',
        description: 'Precios de reparaciones de ropa: Descubra todos nuestros precios para la reparación de sus ropa & cortinas ✅Costurera 7/7 de 7 a 22',
      },
    },
  },

  PricingsPageFull: {
    url: '/tous-nos-tarifs/',
    component: 'src/pagesComponents/home/PricingsPageFull.js',
    seo: {
      title: 'Prix Retouche : Tarifs des Retouche de Vêtements - Tilli',
      description: 'Tarifs & Prix des Retouches Vêtements : Découvrez tous nos prix pour la retouche de vos vêtements & Rideaux ✅Couturier 7/7 de 7h à 22h',
      english: {
        title: 'Alterations Prices: Clothing Alterations Prices - Tilli',
        description: 'Clothing Alterations Prices: Discover all our prices for the alteration of your clothes & Curtains ✅Seamstress 7/7 from 7am to 10pm',
      },
      portuguese: {
        title: 'Preços de retouches: Preços de retouches de roupas - Tilli',
        description: 'Preços de retouches de roupas: Descubra todos os nossos preços para a retouche de suas roupas & Cortinas ✅Costureira 7/7 de 7 a 22',
      },
      german: {
        title: 'Preise für Reparaturen: Preise für Reparaturen von Kleidern - Tilli',
        description: 'Preise für Reparaturen von Kleidern: Entdecken Sie alle unsere Preise für die Reparatur Ihrer Kleider & Vorhänge ✅Schneider 7/7 von 7 bis 22 Uhr',
      },
      italian: {
        title: 'Prezzi per le riparazioni: Prezzi per le riparazioni di vestiti - Tilli',
        description: 'Prezzi per le riparazioni di vestiti: Scopri tutti i nostri prezzi per le riparazioni dei tuoi vestiti & tende ✅Costuriere 7/7 dalle 7 alle 22',
      },
      spanish: {
        title: 'Precios de reparaciones: Precios de reparaciones de ropa - Tilli',
        description: 'Precios de reparaciones de ropa: Descubra todos nuestros precios para la reparación de sus ropa & cortinas ✅Costurera 7/7 de 7 a 22',
      },
    },
  },

  PricingsPageGL: {
    url: '/nos-tarifs-galeries-lafayette/',
    component: 'src/pagesComponents/home/PricingsPageGL.js',
    seo: {
      title: 'Prix Retouche : Tarifs des Retouche de Vêtements - Tilli',
      description: 'Tarifs & Prix des Retouches Vêtements : Découvrez tous nos prix pour la retouche de vos vêtements & Rideaux ✅Couturier 7/7 de 7h à 22h',
    },
  },

  PricingsPageWebView: {
    url: '/nos-tarifs-app/',
    component: 'src/pagesComponents/home/PricingsPageWebView.js',
  },

  OurArtisansPage: {
    url: '/nos-artisans/',
    component: 'src/pagesComponents/home/OurArtisansPage.js',
    seo: {
      title: 'Nos artisans couturiers : leurs paroles - Tilli',
      description: 'Nos Tillistes, artisans couturiers, se livrent sur leur métier et leur relation avec Tilli, notamment leurs meilleurs souvenirs de missions.',
      photo: '/tillistes-board.jpg',
      english: {
        title: 'Our couturiers: their words - Tilli',
        description: 'Our Tillistes, couturiers, talk about their profession and their relationship with Tilli, including their best memories of missions.',
      },
      portuguese: {
        title: 'Our couturiers: their words - Tilli',
        description: 'Our Tillistes, couturiers, talk about their profession and their relationship with Tilli, including their best memories of missions.',
      },
      german: {
        title: 'Our couturiers: their words - Tilli',
        description: 'Our Tillistes, couturiers, talk about their profession and their relationship with Tilli, including their best memories of missions.',
      },
      italian: {
        title: 'Our couturiers: their words - Tilli',
        description: 'Our Tillistes, couturiers, talk about their profession and their relationship with Tilli, including their best memories of missions.',
      },
      spanish: {
        title: 'Our couturiers: their words - Tilli',
        description: 'Our Tillistes, couturiers, talk about their profession and their relationship with Tilli, including their best memories of missions.',
      },
    },
  },

  JobOfferPage: {
    url: '/rejoindre-aventure-tilli/',
    component: 'src/pagesComponents/home/JobOfferPage.js',
    seo: {
      title: 'Rejoindre l’aventure Tilli : toutes nos offres de stage et d’emploi',
      description: 'Tilli recrute ! Consultez toutes nos offres d’emploi en CDI ainsi que nos offres de stage et postulez en ligne.',
      english: {
        title: 'Join the Tilli adventure: all our job offers and internships',
        description: 'Tilli is hiring! Check out all our job offers in full-time employment as well as our internship offers and apply online.',
      },
    },
  },

  TilliXFitle: {
    url: '/tilli-et-fitle/',
    component: 'src/pagesComponents/home/TilliXFitle.js',
    seo: {
      title: 'Retouche Vêtement Fitle : Tilli Partenaire Fitle – Tilli',
      description: ' Qui n’a jamais eu peur de se tromper de taille en achetant ses vêtements sur Internet ou a été déçu en essayant chez lui son vêtement ? Tilli & Fitle décident donc de réunir leurs forces pour apporter une solution complète aux plateformes e-commerce.',
    },
  },

  LookBookPage: {
    url: '/lookbook-hiver-v1-2019-2020/',
    component: 'src/pagesComponents/home/LookBookPage.js',
    seo: {
      title: 'Lookbook hiver v.1 2019-2020',
      description: 'Depuis la naissance de Tilli, notre ambition est de vous prouver que la retouche, la réparation et l’upcycling est à la fois un moyen de faire plus attention à sa consommation de vêtements et une manière de laisser parler sa créativité ! ',
      english: {
        title: 'Lookbook hiver v.1 2019-2020',
        description: 'Since the birth of Tilli, our ambition is to prove to you that alteration, repair and upcycling is both a way to pay more attention to your clothing consumption and a way to let your creativity speak!',
      },
      portuguese: {
        title: 'Lookbook hiver v.1 2019-2020',
        description: 'Since the birth of Tilli, our ambition is to prove to you that alteration, repair and upcycling is both a way to pay more attention to your clothing consumption and a way to let your creativity speak!',
      },
      german: {
        title: 'Lookbook hiver v.1 2019-2020',
        description: 'Since the birth of Tilli, our ambition is to prove to you that alteration, repair and upcycling is both a way to pay more attention to your clothing consumption and a way to let your creativity speak!',
      },
      italian: {
        title: 'Lookbook hiver v.1 2019-2020',
        description: 'Since the birth of Tilli, our ambition is to prove to you that alteration, repair and upcycling is both a way to pay more attention to your clothing consumption and a way to let your creativity speak!',
      },
      spanish: {
        title: 'Lookbook hiver v.1 2019-2020',
        description: 'Since the birth of Tilli, our ambition is to prove to you that alteration, repair and upcycling is both a way to pay more attention to your clothing consumption and a way to let your creativity speak!',
      },
    },
  },

  LookBookNewsletterPage: {
    url: '/lookbook-hiver-v1-2019-2020-newsletter/',
    component: 'src/pagesComponents/home/LookBookNewsletterPage.js',
    seo: {
      title: 'Lookbook hiver v.1 2019-2020',
      description: 'Depuis la naissance de Tilli, notre ambition est de vous prouver que la retouche, la réparation et l’upcycling est à la fois un moyen de faire plus attention à sa consommation de vêtements et une manière de laisser parler sa créativité ! ',
      english: {
        title: 'Lookbook hiver v.1 2019-2020',
        description: 'Since the birth of Tilli, our ambition is to prove to you that alteration, repair and upcycling is both a way to pay more attention to your clothing consumption and a way to let your creativity speak!',
      },
      portuguese: {
        title: 'Lookbook hiver v.1 2019-2020',
        description: 'Since the birth of Tilli, our ambition is to prove to you that alteration, repair and upcycling is both a way to pay more attention to your clothing consumption and a way to let your creativity speak!',
      },
      german: {
        title: 'Lookbook hiver v.1 2019-2020',
        description: 'Since the birth of Tilli, our ambition is to prove to you that alteration, repair and upcycling is both a way to pay more attention to your clothing consumption and a way to let your creativity speak!',
      },
      italian: {
        title: 'Lookbook hiver v.1 2019-2020',
        description: 'Since the birth of Tilli, our ambition is to prove to you that alteration, repair and upcycling is both a way to pay more attention to your clothing consumption and a way to let your creativity speak!',
      },
      spanish: {
        title: 'Lookbook hiver v.1 2019-2020',
        description: 'Since the birth of Tilli, our ambition is to prove to you that alteration, repair and upcycling is both a way to pay more attention to your clothing consumption and a way to let your creativity speak!',
      },
    },
  },

  SustainableFashionPage: {
    url: '/la-mode-durable/',
    component: 'src/pagesComponents/home/SustainableFashionPage.js',
    seo: {
      title: 'La Mode Durable - Tilli',
      description: 'Expression féminine : prendre le temps de s’amuser et de laisser parler sa créativité pour donner une seconde vie à son dressing et son intérieur !',
      photo: '/last-collab-beryl.jpg',
      english: {
        title: 'Sustainable Fashion - Tilli',
        description: 'Feminine expression: take the time to enjoy and let your creativity speak to give a second life to your wardrobe and your interior!',
        photo: '/last-collab-beryl.jpg',
      },
      portuguese: {
        title: 'Moda Durável - Tilli',
        description: 'Expressão feminina: tome o tempo para se divertir e deixe sua criatividade falar para dar uma segunda vida ao seu guarda-roupa e ao seu interior!',
        photo: '/last-collab-beryl.jpg',
      },
      german: {
        title: 'Langfristige Mode - Tilli',
        description: 'Feminine expression: take the time to enjoy and let your creativity speak to give a second life to your wardrobe and your interior!',
        photo: '/last-collab-beryl.jpg',
      },
      italian: {
        title: 'Moda Durabile - Tilli',
        description: 'Espressione femminile: prendi il tempo per divertirti e lascia che la tua creatività parli per dare una seconda vita al tuo guardaroba e al tuo interno!',
        photo: '/last-collab-beryl.jpg',
      },
      spanish: {
        title: 'Moda Durável - Tilli',
        description: 'Expresión femenina: tome el tiempo para divertirse y deje que su creatividad hable para dar una segunda vida a su guardarropa y su interior!',
        photo: '/last-collab-beryl.jpg',
      },
    },
  },

  Retail: {
    url: '/nos-boutiques/',
    component: 'src/pagesComponents/home/Retail.js',
    seo: {
      title: 'Nos Boutiques - Tilli',
      description: 'Découvrez nos 3 boutiques : rue Henry Monnier, rue Notre Dame de Lorette et au Printemps Haussmann',
      photo: '/boutique.jpg',
      english: {
        title: 'Our Boutiques - Tilli',
        description: 'Discover our 3 boutiques: rue Henry Monnier, rue Notre Dame de Lorette and at Printemps Haussmann',
        photo: '/boutique.jpg',
      },
      portuguese: {
        title: 'Nossas Lojas - Tilli',
        description: 'Descubra nossas 3 lojas: rua Henry Monnier, rua Notre Dame de Lorette e no Printemps Haussmann',
        photo: '/boutique.jpg',
      },
      german: {
        title: 'Unsere Läden - Tilli',
        description: 'Entdecken Sie unsere 3 Läden: rue Henry Monnier, rue Notre Dame de Lorette und im Printemps Haussmann',
        photo: '/boutique.jpg',
      },
      italian: {
        title: 'Le nostre Boutiques - Tilli',
        description: 'Scopri le nostre 3 boutiques: rue Henry Monnier, rue Notre Dame de Lorette e al Printemps Haussmann',
        photo: '/boutique.jpg',
      },
      spanish: {
        title: 'Nuestras Tiendas - Tilli',
        description: 'Descubra nuestras 3 tiendas: rue Henry Monnier, rue Notre Dame de Lorette y en Printemps Haussmann',
        photo: '/boutique.jpg',
      },
    },
  },

  ThanksTillisteFormPage: {
    url: '/merci-inscription-tilliste/',
    component: 'src/pagesComponents/home/ThanksTillisteFormPage.js',
    isRouteNotIndexedBySearchEngine: true,
  },

  MainAlterationClothesPage: {
    url: '/retouche-vetement/',
    component: 'src/pagesComponents/home/LandingMainAlterationClothes.js',
    seo: {
      title: 'Retouche & Couture Vêtement : Retouche & Couture Tous Types de Vêtements - Tilli',
      description: 'Retouche Vêtement : Tilli vous propose un service de Couturier à Domicile pour toutes vos retouches et petites coutures. ✅ Disponible 7/7 de 7h à 22h',
      english: {
        title: 'Alterations & Couture Clothes: Alterations & Couture All Types of Clothes - Tilli',
        description: 'Alterations & Couture Clothes: Tilli offers a tailor at home service for all your alterations and small coutures. ✅ Available 7/7 from 7am to 10pm',
      },
      portuguese: {
        title: 'Alterations & Couture Todos os Tipos de Roupas - Tilli',
        description: 'Tilli oferece um serviço de costureiro em casa para todas as suas retouches e pequenas costuras. ✅ Disponível 7/7 das 7h às 22h',
      },
      german: {
        title: 'Anpassungen & Couture Aller Artikel - Tilli',
        description: 'Anpassungen & Couture Aller Artikel: Tilli bietet einen Dienstleister zu Hause für alle Ihre Anpassungen und kleine Kostüme. ✅ Verfügbar 7/7 von 7 bis 22 Uhr',
      },
      italian: {
        title: 'Anpassungen & Couture Tutti i Tipi di Abbigliamento - Tilli',
        description: 'Anpassungen & Couture Tutti i Tipi di Abbigliamento: Tilli offre un servizio di sarto a domicilio per tutte le tue modifiche e piccole costure. ✅ Disponibile 7/7 dalle 7 alle 22',
      },
      spanish: {
        title: 'Alteraciones & Couture Todos los Tipos de Ropa - Tilli',
        description: 'Tilli ofrece un servicio de sastre a domicilio para todas tus modificaciones y pequeñas costuras. ✅ Disponible 7/7 de 7 a 22',
      },
    },
  },

  MainRepairClothesPage: {
    url: '/reparation-vetement/',
    component: 'src/pagesComponents/home/LandingMainRepairClothes.tsx',
    seo: {
      title: 'Réparation & Couture Vêtement : Réparation & Couture Tous Types de Vêtements - Tilli',
      description: 'Réparation Vêtement : Tilli vous propose un service de Couturier à Domicile pour toutes vos réparations et petites coutures. ✅ Disponible 7/7 de 7h à 22h',
      english: {
        title: 'Repairs & Couture Clothes: Repairs & Couture All Types of Clothes - Tilli',
        description: 'Repairs & Couture Clothes: Tilli offers a tailor at home service for all your repairs and small coutures. ✅ Available 7/7 from 7am to 10pm',
      },
      portuguese: {
        title: 'Réparation & Couture Todos os Tipos de Roupas - Tilli',
        description: 'Tilli oferece um serviço de costureiro em casa para todas as suas retouches e pequenas costuras. ✅ Disponível 7/7 das 7h às 22h',
      },
      german: {
        title: 'Reparaturen & Couture Aller Artikel - Tilli',
        description: 'Reparaturen & Couture Aller Artikel: Tilli bietet einen Dienstleister zu Hause für alle Ihre Reparaturen und kleine Kostüme. ✅ Verfügbar 7/7 von 7 bis 22 Uhr',
      },
      italian: {
        title: 'Riparature & Couture Tutti i Tipi di Abbigliamento - Tilli',
        description: 'Riparature & Couture Tutti i Tipi di Abbigliamento: Tilli offre un servizio di sarto a domicilio per tutte le tue riparature e piccole costure. ✅ Disponibile 7/7 dalle 7 alle 22',
      },
      spanish: {
        title: 'Reparaciones & Couture Todos los Tipos de Ropa - Tilli',
        description: 'Tilli ofrece un servicio de sastre a domicilio para todas tus reparaciones y pequeñas costuras. ✅ Disponible 7/7 de 7 a 22',
      },
    },
  },

  MainLeatherGoodsPage: {
    url: '/maroquinerie/',
    component: 'src/pagesComponents/home/LandingMainLeatherGoods.tsx',
    seo: {
      title: 'Artisans maroquiniers : Entretiens et réparation de vos accessoires en cuir - Tilli',
      description: "Entretien et réparation de vos accessoires en cuir : Tilli vous propose un service de maroquinerie de qualité disponible de partout grâce à l'envoi de colis.",
      english: {
        title: 'Leather Goods Repairs & Maintenance: Leather Goods Repairs & Maintenance All Types of Leather Goods - Tilli',
        description: 'Leather Goods Repairs & Maintenance: Tilli offers a leather goods repair and maintenance service available everywhere thanks to package delivery.',
      },
      portuguese: {
        title: 'Manutenção e Reparação de Acessórios em Couro: Manutenção e Reparação de Acessórios em Couro - Tilli',
        description: 'Manutenção e Reparação de Acessórios em Couro: Tilli oferece um serviço de manutenção e reparo de acessórios em couro disponível em todo o mundo graças ao envio de pacotes.',
      },
      german: {
        title: 'Maroquinerie Reparaturen & Wartung: Maroquinerie Reparaturen & Wartung Aller Artikel - Tilli',
        description: 'Maroquinerie Reparaturen & Wartung: Tilli bietet einen Dienstleister zu Hause für alle Ihre Reparaturen und Wartung von Lederwaren. ✅ Verfügbar 7/7 von 7 bis 22 Uhr',
      },
      italian: {
        title: 'Manutenzione e Riparazione di Accessori in Pelle: Manutenzione e Riparazione di Accessori in Pelle - Tilli',
        description: 'Manutenzione e Riparazione di Accessori in Pelle: Tilli offre un servizio di manutenzione e riparazione di accessori in pelle disponibile in tutto il mondo grazie all\'invio di pacchi.',
      },
    },
  },

  MainShoesPage: {
    url: '/cordonnerie/',
    component: 'src/pagesComponents/home/LandingMainShoes.tsx',
    seo: {
      title: 'Artisans cordonniers : Entretiens et réparation de vos chaussures - Tilli',
      description: "Entretien et réparation de vos chaussures : Tilli vous propose un service de cordonnerie de qualité disponible de partout grâce à l'envoi de colis.",
      english: {
        title: 'Shoes Repairs & Maintenance: Shoes Repairs & Maintenance All Types of Shoes - Tilli',
        description: 'Shoes Repairs & Maintenance: Tilli offers a shoes repair and maintenance service available everywhere thanks to package delivery.',
      },
      portuguese: {
        title: 'Reparações e Manutenção de Sapatos: Reparações e Manutenção de Sapatos - Tilli',
        description: 'Reparações e Manutenção de Sapatos: Tilli oferece um serviço de reparo e manutenção de sapatos disponível em todo o mundo graças ao envio de pacotes.',
      },
      german: {
        title: 'Schuhreparaturen & Wartung: Schuhreparaturen & Wartung Aller Artikel - Tilli',
        description: 'Schuhreparaturen & Wartung: Tilli bietet einen Dienstleister zu Hause für alle Ihre Reparaturen und Wartung von Schuhen. ✅ Verfügbar 7/7 von 7 bis 22 Uhr',
      },
      italian: {
        title: 'Riparature & Manutenzione di Scarpe: Riparature & Manutenzione di Scarpe - Tilli',
        description: 'Riparature & Manutenzione di Scarpe: Tilli offre un servizio di riparo e manutenzione di scarpe disponibile in tutto il mondo grazie all\'invio di pacchi.',
      },
      spanish: {
        title: 'Reparaciones y Mantenimiento de Zapatos: Reparaciones y Mantenimiento de Zapatos - Tilli',
        description: 'Reparaciones y Mantenimiento de Zapatos: Tilli ofrece un servicio de reparación y mantenimiento de zapatos disponible en todo el mundo gracias al envío de paquetes.',
      },
    },
  },

  CoutureServicesPage: {
    url: '/nos-services-de-couture/',
    component: 'src/pagesComponents/home/OurServices/CoutureServicesPage.js',
    seo: {
      title: 'Nos Services de couture - Tilli',
      description: 'Retouche et réparations de tous vos vêtements: ourlet, ajustement, transformation, etc.',
      english: {
        title: 'Our Alterations Services - Tilli',
        description: 'Alterations and repairs of all your clothes: hem, adjustment, transformation, etc.',
      },
      portuguese: {
        title: 'Nossos Serviços de Retouche - Tilli',
        description: 'Retouche e reparos de todos seus vêtements: borda, ajuste, transformação, etc.',
      },
      german: {
        title: 'Unsere Anpassungen Services - Tilli',
        description: 'Anpassungen und Reparaturen aller Ihrer Kleidung: Naht, Anpassung, Umwandlung, etc.',
      },
      italian: {
        title: 'I Nostri Servizi di Anpassamento - Tilli',
        description: 'Anpassamenti e riparazioni di tutta la vostra abbigliamento: cucitura, adattamento, trasformazione, etc.',
      },
      spanish: {
        title: 'Nuestros Servicios de Ajuste - Tilli',
        description: 'Ajustes y reparaciones de todo su ropa: bordado, ajuste, transformación, etc.',
      },
    },
  },

  LeatherGoodsServicesPage: {
    url: '/nos-services-de-maroquinerie/',
    component: 'src/pagesComponents/home/OurServices/LeatherGoodsServicesPage.js',
    seo: {
      title: 'Nos Services de maroquinerie - Tilli',
      description: 'Entretien et réparation de tous vos accessoires en cuir: sac, ceinture, chaussures, etc.',
      english: {
        title: 'Our Leather Goods Services - Tilli',
        description: 'Maintenance and repair of all your leather accessories: bag, belt, shoes, etc.',
      },
      portuguese: {
        title: 'Nossos Serviços de Maroquinerie - Tilli',
        description: 'Manutenção e reparo de todos seus acessórios em couro: bolsa, cintura, sapatos, etc.',
      },
      german: {
        title: 'Unsere Maroquinerie Services - Tilli',
        description: 'Wartung und Reparatur aller Ihrer Lederwaren: Tasche, Gürtel, Schuhe, etc.',
      },
      italian: {
        title: 'I Nostri Servizi di Maroquinerie - Tilli',
        description: 'Manutenzione e riparazione di tutti i vostri accessori in pelle: borsa, cintura, scarpe, etc.',
      },
      spanish: {
        title: 'Nuestros Servicios de Marroquinería - Tilli',
        description: 'Mantenimiento y reparación de todos sus accesorios en cuero: bolsa, cinturón, zapatos, etc.',
      },
    },
  },

  EmbroideryServicesPage: {
    url: '/nos-services-de-broderie/',
    component: 'src/pagesComponents/home/OurServices/EmbroideryServicesPage.js',
    seo: {
      title: 'Nos Services de broderie - Tilli',
      description: 'Personnalisation de tous vos vêtements et accessoires: initiales, logo, etc.',
      english: {
        title: 'Our Embroidery Services - Tilli',
        description: 'Customization of all your clothes and accessories: initials, logo, etc.',
      },
      portuguese: {
        title: 'Nossos Serviços de Bordado - Tilli',
        description: 'Personalização de todos seus vêtements e acessórios: inicial, logotipo, etc.',
      },
      german: {
        title: 'Unsere Embroiderie Services - Tilli',
        description: 'Anpassungen und Reparaturen aller Ihrer Kleidung: Naht, Anpassung, Umwandlung, etc.',
      },
      italian: {
        title: 'I Nostri Servizi di Broderie - Tilli',
        description: 'Personalizzazione di tutta la vostra abbigliamento e accessori: iniziali, logo, etc.',
      },
      spanish: {
        title: 'Nuestros Servicios de Bordado - Tilli',
        description: 'Personalización de todo su ropa y accesorios: iniciales, logotipo, etc.',
      },
    },
  },

  ShoesServicesPage: {
    url: '/nos-services-de-cordonnerie/',
    component: 'src/pagesComponents/home/OurServices/ShoesServicesPage.js',
    seo: {
      title: 'Nos Services de cordonnerie - Tilli',
      description: 'Entretien et réparation de tous vos souliers: talon, patin, cirage, etc.',
      english: {
        title: 'Our Shoes Services - Tilli',
        description: 'Maintenance and repair of all your shoes: heel, pad, polish, etc.',
      },
      portuguese: {
        title: 'Nossos Serviços de Cordonnerie - Tilli',
        description: 'Manutenção e reparo de todos seus sapatos: salto, patim, polimento, etc.',
      },
      german: {
        title: 'Unsere Schuh Services - Tilli',
        description: 'Wartung und Reparatur aller Ihrer Schuhe: Heel, Pad, Polieren, etc.',
      },
      italian: {
        title: 'I Nostri Servizi di Cordonnerie - Tilli',
        description: 'Manutenzione e riparazione di tutti i vostri sapelli: salto, patim, polimento, etc.',
      },
      spanish: {
        title: 'Nuestros Servicios de Cordonnería - Tilli',
        description: 'Mantenimiento y reparación de todos sus zapatos: salto, patim, polimento, etc.',
      },
    },
  },

  HomePagePackage: {
    url: '/service-colis/',
    component: 'src/pagesComponents/home/HomePagePackage.tsx',
    seo: {
      title: 'Tilli - Service de couturiers en envoi de colis',
      description: 'Un service simple, de qualité et transparent.',
      english: {
        title: 'Tilli - Tailor service with package delivery',
        description: 'A simple, high-quality and transparent service.',
      },
      portuguese: {
        title: 'Tilli - Serviço de couturiers com envio de pacotes',
        description: 'Um serviço simples, de qualidade e transparente.',
      },
      german: {
        title: 'Tilli - Schneiderdienst mit Paketversand',
        description: 'Ein einfaches, qualitativ hochwertiges und transparentes Service.',
      },
      italian: {
        title: 'Tilli - Servizio di sarto con spedizione di colli',
        description: 'Un servizio semplice, di alta qualità e trasparente.',
      },
      spanish: {
        title: 'Tilli - Servicio de sastre con envío de paquetes',
        description: 'Un servicio simple, de alta calidad y transparente.',
      },
    },
  },

  RepairBonusPage: {
    url: '/bonus-reparation/',
    component: 'src/pagesComponents/home/RepairBonusPage.js',
    seo: {
      title: 'Bonus Réparation - Tilli',
      description: 'Tilli vous propose un bonus réparation pour vous encourager à réparer plutôt que de jeter vos vêtements.',
    },
  },

  LandingCurtainsPage: {
    url: '/retouche-rideaux/',
    component: 'src/templates/LandingCurtainsPage.tsx',
    seo: {
      title: 'Retouche Rideaux & Voilages à Domicile - Tilli',
      description: 'Retouche Rideaux & Voilages : Tilli vous envoie un Couturier à Domicile pour Récupérer vos Rideaux pour les Retoucher selon votre demande ✅7/7 de 7h à 22h',
      english: {
        title: 'Tilli - Tailor service with package delivery',
        description: 'A simple, high-quality and transparent service.',
      },
      portuguese: {
        title: 'Tilli - Serviço de couturiers com envio de pacotes',
        description: 'Um serviço simples, de qualidade e transparente.',
      },
      german: {
        title: 'Tilli - Schneiderdienst mit Paketversand',
        description: 'Ein einfaches, qualitativ hochwertiges und transparentes Service.',
      },
      italian: {
        title: 'Tilli - Servizio di sarto con spedizione di colli',
        description: 'Un servizio semplice, di alta qualità e trasparente.',
      },
      spanish: {
        title: 'Tilli - Servicio de sastre con envío de paquetes',
        description: 'Un servicio simple, de alta calidad y transparente.',
      },
    },
  },

  LaRubrique: {
    url: '/la-rubrique/',
    seo: {
      title: 'La Rubrique Tilli : toutes les inspirations',
      description: 'Trouvez des inspirations pour votre dressing en fonction de vos habits et de vos matières : broderies, poches, transformations de vêtements…',
      photo: '/la-rubrique.jpg',
      english: {
        title: 'The blog of Tilli: all the inspirations',
        description: 'Find inspiration for your dressing based on your clothes and materials: embroidery, pockets, transformations of clothes…',
      },
      portuguese: {
        title: 'A blogue de Tilli: todas as inspirações',
        description: 'Encontre inspiração para o seu guarda-roupa com base em suas roupas e materiais: bordados, bolsos, transformações de roupas…',
      },
      german: {
        title: 'Die Blog-Seite von Tilli: alle Inspirationen',
        description: 'Finde Inspiration für deinen Garderoben basierend auf deinen Kleidern und Materialien: Bordüre, Taschen, Umformungen von Kleidern…',
      },
      italian: {
        title: 'Il blog di Tilli: tutte le ispirazioni',
        description: 'Trova ispirazione per il tuo guardaroba basata sui tuoi vestiti e materiali: broderie, tasche, trasformazioni di vestiti…',
      },
      spanish: {
        title: 'El blog de Tilli: todas las inspiraciones',
        description: 'Encuentra inspiración para tu guardarropa basada en tus prendas y materiales: bordados, bolsillos, transformaciones de prendas…',
      },
    },
  },
};
