// cspell:disable
module.exports = {
  Step1: {
    url: '/reserver-votre-couturier-a-domicile/',
    component: 'src/pagesComponents/order/Step1.js',
    seo: {
      title: 'Réserver votre couturier à Paris, Marseille,… - Tilli',
      description: 'Prenez commande en quelques clics pour l‘entretien de votre dressing. Disponible partout en France',
      english: {
        title: 'Tilli - Tailor service with package delivery',
        description: 'A simple, high-quality and transparent service.',
      },
      portuguese: {
        title: 'Tilli - Serviço de couturiers com envio de pacotes',
        description: 'Um serviço simples, de qualidade e transparente.',
      },
      german: {
        title: 'Tilli - Schneiderdienst mit Paketversand',
        description: 'Ein einfaches, qualitativ hochwertiges und transparentes Service.',
      },
      italian: {
        title: 'Tilli - Servizio di sarto con spedizione di colli',
        description: 'Un servizio semplice, di alta qualità e trasparente.',
      },
      spanish: {
        title: 'Tilli - Servicio de sastre con envío de paquetes',
        description: 'Un servicio simple, de alta calidad y transparente.',
      },
    },
  },

  Step1Summary: {
    url: '/modifier-votre-commande/',
    component: 'src/pagesComponents/order/Step1Summary.js',
    seo: {
      title: 'Réserver votre couturier à Paris, Marseille,… - Tilli',
      description: 'Prenez commande en quelques clics pour l‘entretien de votre dressing. Disponible partout en France',
      english: {
        title: 'Tilli - Tailor service with package delivery',
        description: 'A simple, high-quality and transparent service.',
      },
      portuguese: {
        title: 'Tilli - Serviço de couturiers com envio de pacotes',
        description: 'Um serviço simples, de qualidade e transparente.',
      },
      german: {
        title: 'Tilli - Schneiderdienst mit Paketversand',
        description: 'Ein einfaches, qualitativ hochwertiges und transparentes Service.',
      },
      italian: {
        title: 'Tilli - Servizio di sarto con spedizione di colli',
        description: 'Un servizio semplice, di alta qualità e trasparente.',
      },
      spanish: {
        title: 'Tilli - Servicio de sastre con envío de paquetes',
        description: 'Un servicio simple, de alta calidad y transparente.',
      },
    },
  },

  Step2: {
    // cSpell:ignore etes
    url: '/quand-etes-vous-disponible/',
    component: 'src/pagesComponents/order/Step2.js',
    seo: {
      title: 'Réserver votre couturier à Paris, Marseille,… - Tilli',
      description: 'Prenez commande en quelques clics pour l‘entretien de votre dressing. Disponible partout en France',
      english: {
        title: 'Tilli - Tailor service with package delivery',
        description: 'A simple, high-quality and transparent service.',
      },
      portuguese: {
        title: 'Tilli - Serviço de couturiers com envio de pacotes',
        description: 'Um serviço simples, de qualidade e transparente.',
      },
      german: {
        title: 'Tilli - Schneiderdienst mit Paketversand',
        description: 'Ein einfaches, qualitativ hochwertiges und transparentes Service.',
      },
      italian: {
        title: 'Tilli - Servizio di sarto con spedizione di colli',
        description: 'Un servizio semplice, di alta qualità e trasparente.',
      },
      spanish: {
        title: 'Tilli - Servicio de sastre con envío de paquetes',
        description: 'Un servicio simple, de alta calidad y transparente.',
      },
    },
  },

  Step3: {
    url: '/merci-d-avoir-pris-rdv-avec-tilli/',
    component: 'src/pagesComponents/order/Step3.js',
    seo: {
      title: 'Réserver votre couturier à Paris, Marseille,… - Tilli',
      description: 'Prenez commande en quelques clics pour l‘entretien de votre dressing. Disponible partout en France',
      english: {
        title: 'Tilli - Tailor service with package delivery',
        description: 'A simple, high-quality and transparent service.',
      },
      portuguese: {
        title: 'Tilli - Serviço de couturiers com envio de pacotes',
        description: 'Um serviço simples, de qualidade e transparente.',
      },
      german: {
        title: 'Tilli - Schneiderdienst mit Paketversand',
        description: 'Ein einfaches, qualitativ hochwertiges und transparentes Service.',
      },
      italian: {
        title: 'Tilli - Servizio di sarto con spedizione di colli',
        description: 'Un servizio semplice, di alta qualità e trasparente.',
      },
      spanish: {
        title: 'Tilli - Servicio de sastre con envío de paquetes',
        description: 'Un servicio simple, de alta calidad y transparente.',
      },
    },
  },

  Payment: {
    // let the url without trailing slash, otherwise the page didn't load
    url: '/enregistrer-votre-moyen-de-paiement',
    component: 'src/pagesComponents/order/containers/PaymentContainer.js',
    seo: {
      title: 'Enregistrer votre moyen de paiement - Tilli',
      english: {
        title: 'Tilli - Tailor service with package delivery',
        description: 'A simple, high-quality and transparent service.',
      },
      portuguese: {
        title: 'Tilli - Serviço de couturiers com envio de pacotes',
        description: 'Um serviço simples, de qualidade e transparente.',
      },
      german: {
        title: 'Tilli - Schneiderdienst mit Paketversand',
        description: 'Ein einfaches, qualitativ hochwertiges und transparentes Service.',
      },
      italian: {
        title: 'Tilli - Servizio di sarto con spedizione di colli',
        description: 'Un servizio semplice, di alta qualità e trasparente.',
      },
      spanish: {
        title: 'Tilli - Servicio de sastre con envío de paquetes',
        description: 'Un servicio simple, de alta calidad y transparente.',
      },
    },
  },

  SuccessPayment: {
    url: '/moyen-de-paiement-enregistre/',
    component: 'src/pagesComponents/order/containers/PaymentSuccessContainer.js',
    seo: {
      title: 'Votre moyen de paiement a bien été enregistré - Tilli',
      english: {
        title: 'Tilli - Tailor service with package delivery',
        description: 'A simple, high-quality and transparent service.',
      },
    },
  },

  PayInOrder: {
    // let the url without trailing slash, otherwise the page didn't load
    url: '/regler-votre-commande',
    component: 'src/pagesComponents/order/containers/PayInContainer.js',
    seo: {
      title: 'Enregistrer votre moyen de paiement - Tilli',
      english: {
        title: 'Tilli - Tailor service with package delivery',
        description: 'A simple, high-quality and transparent service.',
      },
    },
  },

  SuccessPayIn: {
    url: '/paiement-enregistre/',
    component: 'src/pagesComponents/order/containers/PayInSuccessContainer.js',
    seo: {
      title: 'Votre moyen de paiement a bien été enregistré - Tilli',
      english: {
        title: 'Tilli - Tailor service with package delivery',
        description: 'A simple, high-quality and transparent service.',
      },
    },
  },

  PaymentBrand: {
    // let the url without trailing slash, otherwise the page didn't load
    url: '/enregistrer-un-moyen-de-paiement',
    component: 'src/pagesComponents/order/containers/PaymentContainerBrand.js',
    seo: {
      title: 'Enregistrer un moyen de paiement - Tilli',
      english: {
        title: 'Tilli - Tailor service with package delivery',
        description: 'A simple, high-quality and transparent service.',
      },
    },
  },

  SuccessPaymentBrand: {
    url: '/moyen-de-paiement-marque-enregistre/',
    component: 'src/pagesComponents/order/containers/PaymentSuccessContainerBrand.js',
    seo: {
      title: 'Votre moyen de paiement a bien été enregistré - Tilli',
      english: {
        title: 'Tilli - Tailor service with package delivery',
        description: 'A simple, high-quality and transparent service.',
      },
    },
  },

  AdditionalSlots: {
    // let the url without trailing slash, otherwise the page didn't load
    url: '/creneaux-supplementaires',
    component: 'src/pagesComponents/order/AdditionalSlotsPage.js',
    seo: {
      title: 'Créneaux supplémentaires pour ma commande',
      english: {
        title: 'Tilli - Tailor service with package delivery',
        description: 'A simple, high-quality and transparent service.',
      },
    },
  },

  CommunicationPage: {
    // let the url without trailing slash, otherwise the page didn't load
    url: '/conversation',
    component: 'src/pagesComponents/order/containers/CommunicationPageContainer.js',
    seo: {
      title: 'Messagerie - Tilli',
      english: {
        title: 'Tilli - Tailor service with package delivery',
        description: 'A simple, high-quality and transparent service.',
      },
    },
  },
};
